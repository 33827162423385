import shopifyGenericDefaults from '../shopify-generic/config.js';

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  Widgets: [
    ...shopifyGenericDefaults.Widgets,
    {
      name: 'SearchBoxSecondary',
      type: 'SearchBox',
      location: { selector: '#cm-search-box-secondary', class: 'cm-search-box' },
      disableDropdown: true,
      template: 'SearchBox',
    },
  ],
};
